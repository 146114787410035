const SELECTORS = {
    resultsOrderStore: '.js-results-order-store',
    countOrderStore: '.js-count-order-store',
    filterOrderStore: '.js-filter-order-store'
};

$(document).ready(function() {
   
    $('#DateFilter').on('change', function(e) {
        e.preventDefault();
        const selectedValue = $(this).val();
        const url = $(this).closest('form').attr('action');
        const formData = $(this).closest('form').serialize();
        const updatedUrl = url + '?' + formData;
        $.spinner().start();

        $.ajax({
            url: updatedUrl,
            type: 'GET',
            dataType: 'json',
            success: function(data) {
                $(SELECTORS.resultsOrderStore).empty().append(data.ordersingleStoreTemplate);
                $(SELECTORS.countOrderStore).empty().text(data.total);
                $(SELECTORS.filterOrderStore).selectpicker('refresh');
                $.spinner().stop();
            },
            error: function(xhr, status, error) {
                console.error('Error occurred:', error);
            }
        });
    });
});
