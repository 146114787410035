'use strict';

const { alertMessage } = require('../common/common');

const SELECTORS = {
    element: '.js-order-detail',
    refundButton: '.js-refund-action',
    detailButton: '.js-detail-action',
    form: '#returnForm',
    submitRefund: '.js-submit-refund',
    refundCheckbox: '.js-refund-product-checkbox',
    openModalBtn: '.js-return-open-modal',
    confirmReturnModal: '#confirmCreateReturnModal'
};

const MODES = {
    refund: 'refund',
    detail: 'detail'
}

class OrderDetail {
    constructor(element) {
        this.element = $(element);
        this.initEvents();
    }

    initEvents() {
        this.onRefundClick();
        this.onSubmit();
        this.onChange();
        this.showReturnCaseAlert();
    }

    /**
     * Handles the click event on the refund button
     */
    onRefundClick() {
        $(SELECTORS.refundButton).on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.togglePageMode(MODES.refund);
        });

        $(SELECTORS.detailButton).on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.togglePageMode(MODES.detail);
        });
    }

    /**
     * Toggles the page mode between refund and detail
     * @param {string} mode
     */
    togglePageMode(mode) {
        this.element.attr('data-mode', mode);
    }

    /**
     * Handles submit form
     */
    onSubmit() {
        $(SELECTORS.form).on('submit', async function (e) {
            e.preventDefault();
            $.spinner().start();
            var $form = $(this);
            var url = $form.attr('action');
            var body = buildBody($form.serializeArray());

            //VALIDATE FORM
            let validationError = validateForm(body.formList);
            if (validationError) {
                $.spinner().stop();
                $(SELECTORS.confirmReturnModal).modal('hide');
                return;
            }

            //BUILD FORM DATA
            let orderNumber = $(SELECTORS.element).data('orderno');
            var formdata = new FormData();
            formdata.append('orderNumber', orderNumber);
            formdata.append('formList', JSON.stringify(body.formList));

            // CALL API
            try {

                const response = await fetch(url, {
                    method: 'POST',
                    body: formdata,
                });

                const data = await response.json();

                if (response.status === 200) {
                    if (!data.success) {
                        alertMessage(data.msg, 'cc-alert--danger');
                    } else {
                        alertMessage(data.msg, 'cc-alert--success');
                    }

                    var currentUrl = window.location.href;
                    var newUrl = removeUrlParameter(currentUrl, 'pageMode');
                    if(newUrl.indexOf('&createReturn=true') > -1){
                        newUrl = newUrl.replace('&createReturn=true', '&createReturn=false');
                    }
                    
                    window.location.href = `${newUrl}&returnConfirmed=true`;

                } else {
                    $.spinner().stop();
                    alertMessage(data.msg, 'cc-alert--danger');
                }

            } catch (error) {
                console.error(error);
                $.spinner().stop();
            }

        });
    }

    /**
     * Handles error form and confirm refund button
     */
    onChange() {
        //CHECK FORM ERROR
        $('select').on('change', function () {
            var $select = $(this);
            let id = $select.attr('id')
            if ($select.val() > 0) {
                $('#' + id + '-error').removeClass('d-block');
            }
        })

        // ENABLE/DISABLE CONFIRM BUTTON
        $(SELECTORS.refundCheckbox).on('change', function () {
            if ($(this).is(':checked')) {
                $(SELECTORS.submitRefund).prop('disabled', false);
                $(SELECTORS.openModalBtn).prop('disabled', false);
                handleSelectsVisibility($(this).val(), 'show');
            } else {
                let isAnyChecked = $(SELECTORS.refundCheckbox).is(':checked');
                $(SELECTORS.submitRefund).prop('disabled', !isAnyChecked);
                $(SELECTORS.openModalBtn).prop('disabled', !isAnyChecked);
                let uuid = $(this).val();
                $(`.invalid-feedback-${uuid}`).removeClass('d-block');
                handleSelectsVisibility($(this).val(), 'hide');
            }
        });
    }

    showReturnCaseAlert(){
        customAlertMessage('Richiesta di reso accettata!', 'cc-alert--success', false);
    }
}


/**
    HELPERS
*/
function buildBody(array) {

    let orderNumber = $(SELECTORS.element).data('orderno');
    let body = {
        orderNumber: orderNumber,
        formList: []
    }

    array.forEach(el => {
        if (el.name.includes('refund-lineItem')) {
            let reason = $(`#refund-${el.value}-reason`).val();
            let quantity = $(`#refund-${el.value}-quantity`).val();
            let obj = { itemQty: quantity, uuid: el.value, reasonCode: reason }
            body.formList.push(obj)
        }
    });

    return body;
}

function validateForm(form) {
    let error = false

    form.forEach(el => {
        if (el.reasonCode <= 0) {
            $('#refund-' + el.uuid + '-reason-error').addClass('d-block');
            error = true;
        }
        if (el.itemQty <= 0) {
            $('#refund-' + el.uuid + '-quantity-error').addClass('d-block');
            error = true;
        }
    });

    return error;
}

function handleSelectsVisibility(uuid, action) {

    let reasonSelect = $(`.uuid-${uuid}-wrapper .js-dynamic-reason-select`);
    let quantitySelect = $(`.uuid-${uuid}-wrapper .js-dynamic-qty-select`);
    if (action == 'show') {
        reasonSelect.removeClass('d-none')
        quantitySelect.removeClass('d-none')
    }
    if (action == 'hide') {
        reasonSelect.addClass('d-none')
        quantitySelect.addClass('d-none')
    }

}

function removeUrlParameter(url, parameter) {
    // Split the URL into parts
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
        // Get the parameters part of the URL
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlParts[1].split(/[&;]/g);

        // Create a new parameters array excluding the parameter to remove
        for (var i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        // Reconstruct the URL without the parameter
        url = urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
    }
    return url;
}

/**
 * Display an alert message
 * @param {String} $text - Text to display
 * @param {String} $class - Class to add to the alert
 */
function customAlertMessage($text, $class, enableTimeout) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('returnConfirmed') === 'true') {

        const successTimeout = 3000;
        const dangerTimeout = 6000;
        let timeout = successTimeout;


        $('.js-alert-return').addClass($class).removeClass('cc-active').addClass('cc-active');

        if ($('.js-alert-return').hasClass('cc-alert--danger')) {
            timeout = dangerTimeout;
        }

        if (enableTimeout) {
            setTimeout(() => {
                $('.js-alert-return').removeClass($class).removeClass('cc-active');
            }, timeout);
        }

        $('.js-close-alert-return').off('click').on('click', () => {
            $('.js-alert-return').removeClass($class).removeClass('cc-active');
        });
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new OrderDetail(element);
    });
};
