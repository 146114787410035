'use strict';

const PayPalExpressModel = require('./payPalExpress');

let that = null;

/**
 * Initiates a PayPalButtonCart model
 * @param {string} payPalBtnSelector A container class where PayPal button will be initiated
 */
function PayPalCartModel(payPalBtnSelector) {
    PayPalExpressModel.call(this, payPalBtnSelector);

    that = this;
}

PayPalCartModel.prototype = Object.create(PayPalExpressModel.prototype);

/**
 * Shows errors if paypal widget was closed with errors for Cart page
 * @param {Object} error - An error object
 */
PayPalCartModel.prototype.onError = function(error) {
    const helper = require('../../helpers/helper');

    if (!helper.handleValidationAddressResult(error) && window.location.href !== window.paypalUrls.cartPage) {
        window.location.href = window.paypalUrls.cartPage;
    }

    const errorMsg = error?.message === window.paypalConstants.ZERO_AMOUNT_ERROR_MESSAGE
        ? error.message : window.paypalConstants.FLASH_MESSAGE_ERROR_INTERNAL_SERVER;

    that.alertHandler.showError(errorMsg);
};

module.exports = PayPalCartModel;
