'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
const processInclude = require('base/util');

processInclude(require('./common/common'));
$(() => {
    processInclude(require('base/components/spinner'));
    processInclude(require('./components'));
    processInclude(require('./experience'));
    processInclude(require('./product/quickView'));
    processInclude(require('./pages'));
    processInclude(require('./login'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('./account'));
    processInclude(require('./order'));
    processInclude(require('./orderHistory'));
    processInclude(require('./clerkSession'));
});

require('bootstrap/js/src/collapse.js');
require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/tab.js');
require('bootstrap/js/src/tooltip.js');
require('bootstrap-select/js/bootstrap-select');
require('slick-carousel/slick/slick');
require('bootstrap/js/src/alert.js');