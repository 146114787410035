'use strict';

const SELECTORS = {
    letterSelector: '[id^="cg-letter-selector-"], [id*=" cg-letter-selector-"]',
    brandsSlider: '.js-brand-tables-slider',
    tableLetter: '.js-brand-letter',
    header: '.js-header'
}

const CLASSES = {
    activeClass: 'cc-active'
}

class BrandSlider {
    constructor() {
        this.letterSelector = $(SELECTORS.letterSelector);
        this.tableLetter = $(SELECTORS.tableLetter);
        this.brandsSlider = $(SELECTORS.brandsSlider);
        this.initEvents();
    }

    initEvents() {
        this.applyScrollToLetterFilters();
        this.addActiveClassOnScroll();
    }

    applyScrollToLetterFilters() {
        const $letterSelector = this.letterSelector;
        $(this.letterSelector).each((index, element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();

                if (window.isDesktop) {
                    let targetId = event.target.id;
                    let letter = targetId.substring(19);
                    let navbarHeight = document.documentElement.style.getPropertyValue('--headerHeight').replace('rem', '') * 10;
                    let indexHeight = $('.js-brands-list-index').outerHeight();
                    let targetOffset = $('#cg-section-' + letter).offset().top - navbarHeight - indexHeight + 2;
                    $('html, body').animate({ scrollTop: targetOffset }, 'slow');

                    $(this.letterSelector).each(function () {
                        $(this).removeClass(CLASSES.activeClass);
                    });
                    $(event.currentTarget).addClass(CLASSES.activeClass); 
                } else {
                    $(this.brandsSlider).slick('slickGoTo', index);
                }
            });
        });

        $(this.brandsSlider).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $letterSelector.removeClass(CLASSES.activeClass);
            $($letterSelector[nextSlide]).addClass(CLASSES.activeClass);
        });
    }

    addActiveClassOnScroll() {
        if ((window.isTablet || window.isDesktop)) {
            const $tableLetter = this.tableLetter;
            const $letterSelectors = this.letterSelector;

            $(window).on('scroll', function() {
                $tableLetter.each(function(index, letter) {
                    const letterTop = $(letter).offset().top;
                    const scrollTop = $(window).scrollTop();
                    const navbarHeight = document.documentElement.style.getPropertyValue('--headerHeight').replace('rem', '') * 10;
                    const indexHeight = $('.js-brands-list-index').outerHeight();

                    if (scrollTop >= (letterTop - navbarHeight - indexHeight)) {
                        const indexLetter = $(letter).data('index');
                        $letterSelectors.removeClass(CLASSES.activeClass);
                        $(indexLetter).addClass(CLASSES.activeClass);
                    }
                });
            });
        }
    }
}

$(function() {
    new BrandSlider();
});