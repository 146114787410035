'use strict';

const SELECTORS = {
    promotionDetail: '.js-promotion-pdp',
    assemblyDetail: '.js-assembly-pdp',
    specialProduct: '.js-special-product-pdp',
    addToCartWrap: '.js-addToCart-wrap',
    promotionAccordion: '.js-promotion-accordion',
    assemblyAccordion: '.js-assembly-accordion',
    specialAccordion: '.js-special-product',
    headerMain: '.js-header__main',
    footerBottom: '.js-footer-bottom'
};

const CLASSES = {
    promoClosed: 'cc-pdp__promotion-detail--closed',
    assemblyClosed: 'cc-pdp__assembly-detail--closed',
    specialClosed: 'cc-pdp__product-special-detail--closed',
    fixed: 'cc-fixed'
}

/**
 * Handles the toggling of promotion and assembly details.
 */
class DetailToggle {
    constructor() {
        this.togglePromotionDetail = this.togglePromotionDetail.bind(this);
        this.toggleAssemblyDetail = this.toggleAssemblyDetail.bind(this);
        this.toggleSpecialProduct = this.toggleSpecialProduct.bind(this);
        this.initEvents();
    }

    initEvents() {
        $('body').on('show.bs.collapse hide.bs.collapse', SELECTORS.promotionAccordion, this.togglePromotionDetail);
        $('body').on('show.bs.collapse hide.bs.collapse', SELECTORS.assemblyAccordion, this.toggleAssemblyDetail);
        $('body').on('show.bs.collapse hide.bs.collapse', SELECTORS.specialAccordion, this.toggleSpecialProduct);
    }

    togglePromotionDetail() {
        $(SELECTORS.promotionDetail).toggleClass(CLASSES.promoClosed);
    }

    toggleAssemblyDetail() {
        $(SELECTORS.assemblyDetail).toggleClass(CLASSES.assemblyClosed);
    }

    toggleSpecialProduct() {
        $(SELECTORS.specialProduct).toggleClass(CLASSES.specialClosed);
    }
}

/**
 * Manages the add to cart position based on scroll and viewport.
 */
class AddToCartPosition {
    constructor() {
        this.isClassAdded = true;
        this.$element = $(SELECTORS.addToCartWrap);
        this.handleScroll = this.handleScroll.bind(this);
        this.initEvents();
    }

    initEvents() {
        $(window).on('scroll', this.handleScroll);
    }

    handleScroll() {
        if (this.$element.length > 0) {
            const isElementOutViewport = () => {
                const headerHeightPX = $(SELECTORS.headerMain).outerHeight(true);
                const viewportTop = $(window).scrollTop() + headerHeightPX;
                const viewportBottom = viewportTop + $(window).height();
                const elemTop = this.$element.offset().top;
                const elemBottom = elemTop + this.$element.outerHeight();
                return elemBottom < viewportTop || elemTop > viewportBottom;
            };
        
            const isFooterVisible = () => {
                const footerTop = $(SELECTORS.footerBottom).offset().top;
                const viewportBottom = $(window).scrollTop() + $(window).height();
                return viewportBottom >= footerTop;
            };
        
            if (isElementOutViewport() && !this.isClassAdded && !isFooterVisible()) {
                this.$element.addClass(CLASSES.fixed);
                this.isClassAdded = true;
            } else if ((!isElementOutViewport() || isFooterVisible()) && this.isClassAdded) {
                this.$element.removeClass(CLASSES.fixed);
                this.isClassAdded = false;
            }
        }
    }
}

$(() => {
    new DetailToggle();
    if (window.isMobile && $(SELECTORS.addToCartWrap).length > 0) {
        new AddToCartPosition();
    }
});