'use strict';

const PayPalBaseModel = require('./payPalBase');
const helper = require('../../helpers/helper');
const api = require('../../helpers/api');

/**
 * Initiates a PayPalButtonExpress model (pvp, pdp, cart, mini-cart)
 * @param {string} payPalBtnSelector A container class where PayPal button will be initiated
 */
function PayPalExpressModel(payPalBtnSelector) {
    PayPalBaseModel.call(this, payPalBtnSelector);
}

PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);

/**
 * Process the generic (not Ba) Paypal button flow (cart, pdp, mini-cart)
 */
PayPalExpressModel.prototype.processGenericBtnFlow = function() {

    // For GA4 flow
    sessionStorage.setItem('paypalexpress', 'true');

    const that = this;

    helper.returnFromCart.call(that, {})
        .catch((error) => {
            this.loader.hide();

            if (this.payPalBtnSelector === '.js-paypal-button-on-cart-page') {
                this.onError(error);
            } else if (!helper.handleValidationAddressResult(error)) {
                window.location.href = window.paypalUrls.cartPage;
            } else if (this.payPalBtnSelector.includes('.paypal-pdp-button')) {
                helper.removeAllProductsFromCart();
            }
        });
};

/**
 * onShippingAddressChange PayPal pop-up hook handler
 * @param {Object} data with shippingAddress
 * @param {Object} actions object with events
 */
PayPalExpressModel.prototype.onShippingAddressChange = async(data, actions) => {

    // Add GA4 event (click on paypal express button)
    $('.js-minicart').trigger('checkout:express');

    const validateShippingAddressResponse = await api.validatePayPalShippingAddress(data.shippingAddress);

    if (validateShippingAddressResponse.error) {
        await actions.reject(
            data.errors[validateShippingAddressResponse.errorCodes.shift()]
        );
    }
};

/**
 * Initiates a Paypal button on express checkout page (Cart, mini-cart, pdp)
 * Is used with adjacent instances (cart, pdp, pvp)
 */
PayPalExpressModel.prototype.initPayPalButton = function() {
    this.loader.show();

    const btnInitResult = window.paypal.Buttons(Object.assign(this.createBtnGeneralOption(),
        { onShippingAddressChange: this.onShippingAddressChange }));

    if (btnInitResult.isEligible()) {
        btnInitResult.render(this.payPalBtnSelector);

        this.loader.hide();
    }
};

module.exports = PayPalExpressModel;
