'use strict';

const formValidation = require('../components/formValidation');
const pwdToggler = require('../components/pwdToggler');
const {alertMessage} = require('../common/common');

const location = window.location;

    /**
     * Parses the html for a modal window
     * @param {string} html - representing the body and footer of the modal window
     *
     * @return {Object} - Object with properties body and footer.
     */
    function parseHtml(html) {
        var $html = $('<div>').append($.parseHTML(html));
        return { body: $html.contents() };
        return { body: body };
    }

function handleAjax(url, form, type, successCallback, errorCallback) {
    $('body').spinner().start();
    $.ajax({
        url: url,
        type: type,
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            successCallback(data);
        },
        error: function (data) {
            $('body').spinner().stop();
            errorCallback(data);
        }
    });
}

module.exports = {
    login: function () {
        $('body').on('submit','form.js-login-form', function(e) {
            e.preventDefault();
            const form = $(this);
            const input = form.find('input[data-password]');
            const invalidFeed = input.closest('.cc-form__wrap').find('.invalid-feedback');
            const containerInput = invalidFeed.closest('.cc-form__wrap');
            const url = form.attr('action');

            handleAjax(url, form, 'post', function (data) {
                if (!data.success) {
                    formValidation(form, data, invalidFeed, containerInput);
                    $('form.js-login-form').trigger('login:error', data);
                    $('body').spinner().stop();
                } else {
                    $('form.js-login-form').trigger('login:success', data);
                    containerInput.removeClass('cc-form__wrap--error');
                    invalidFeed.removeClass('cc-message--visible');

                    //Push data to analytics
                    if(typeof dataLayer != 'undefined'){
                        if(data.analyticsData)
                            dataLayer.push(data.analyticsData);
                    }

                    if ($(form).closest('.js-login-wrapper').hasClass('js-login-page')){
                        location.href = data.redirectUrl;
                    } else{
                        window.location.reload();
                    }

                    if (sessionStorage.getItem('subscriptionFlow')) {
                        const subscriptionFlow = JSON.parse(sessionStorage.getItem('subscriptionFlow'));
                        if (subscriptionFlow && subscriptionFlow.cartStep) {
                            subscriptionFlow.loginStep = true;
                            sessionStorage.setItem('subscriptionFlow', JSON.stringify(subscriptionFlow));
                        }
                    }
                }
            }, function (data) {
                $('form.js-login-form').trigger('login:error', data);
            });

            return false;
        });
    },

    logout: function () {
        $('body').on('click','.js-dropdown-logout', function(e) {
            e.preventDefault();
            const url = $(this).attr('value') ? $(this).attr('value') : $(this).attr('href');
            const notLoggedOutMessage = $('.js-dropdown-logout').data('message');

            const homeUrl = $('#js-page-dataset').data('home');
            const currentRoute = $('#js-page-dataset').data('route');
            
            $('body').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (data) {
                    if (data.success){
                        if(currentRoute !== 'Order-Confirm'){
                            window.location.reload();
                        } else{
                            window.location.href = homeUrl;
                        }
                        return;
                    }

                    $('body').spinner().stop();
                },
                error: function () {
                    alertMessage(notLoggedOutMessage, 'alert-danger');
                    $('body').spinner().stop();
                }
            });
        })
    },

    register: function () {
        $('body').on('submit','form.js-registration-form', function(e) {
            e.preventDefault();
            const form = $(this);
            const globalMessageWrap = $(form).find('.js-global-error-message');
            const url = form.attr('action');

            handleAjax(url, form, 'post', function (data) {

                if (!data.success) {
                    alertMessage(data.errorMessage, 'cc-alert--danger');
                    $('form.js-registration-form').trigger('login:register:error', data);
                    formValidation(form, data);
                    $('body').spinner().stop();
                } else {

                    $(globalMessageWrap).empty();
                    $('form.js-registration-form').trigger('login:register:success', data);

                    if ($(form).closest('.js-login-wrapper').hasClass('js-login-page')){
                        location.href = data.redirectUrl;
                    } else {
                        window.localStorage.setItem("wasRegistered", true);

                        if (sessionStorage.getItem('subscriptionFlow')) {
                            const subscriptionFlow = JSON.parse(sessionStorage.getItem('subscriptionFlow'));
                            if (subscriptionFlow && subscriptionFlow.cartStep) {
                                subscriptionFlow.loginStep = true;
                                sessionStorage.setItem('subscriptionFlow', JSON.stringify(subscriptionFlow));
                            }
                        }

                        //Push data to analytics
                        if(typeof dataLayer != 'undefined'){   
                            if(data.analyticsData)
                                dataLayer.push(data.analyticsData);
                        }

                        window.location.reload();
                    }
                }
            }, function (err) {
                alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
            });

            return false;
        });
    },

    completeProfile: function () {
        $('body').on('submit','form.js-complete-profile-form', function(e) {
            e.preventDefault();
            const form = $(this);
            const globalMessageWrap = $(form).find('.js-global-error-message');
            const url = form.attr('action');

            handleAjax(url, form, 'post', function (data) {
                if (!data.success) {
                    alertMessage(data.errorMessage, 'cc-alert--danger');
                    formValidation(form, data);
                    $('form.js-complete-profile-form').trigger('login:completeProfile:error', data);
                } else {
                    $(globalMessageWrap).empty();
                    $('form.js-complete-profile-form').trigger('login:completeProfile:success', data);
                    location.href = data.redirectUrl;
                }
            }, function (err) {
                alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
            });

            return false;
        });
    },

    resubmitEmail: function () {
        $('body').on('submit', 'form.js-confirm-mail-form', function (e) {
            e.preventDefault();
            const form = $(this);
            const globalMessageWrap = form.find('.js-global-error-message');
            const url = form.attr('action');
            const emailInput = form.find('#resubmit-email').val();

            handleAjax(url, form, 'post', function (data) {
                if (!data.success) {
                    alertMessage(data.errorMessage, 'cc-alert--danger');
                    $('body').spinner().stop();
                    formValidation(form, data);
                } else {
                    let parsedHtml = parseHtml(data.renderedTemplate);
                    $('body').spinner().stop();
                    $('.js-render-resubmitMail').empty();
                    $('.js-render-resubmitMail').html(parsedHtml.body);
                    alertMessage(data.successMessage, 'cc-alert--success');
                }
            }, function (err) {
                $('body').spinner().stop();
                alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
            });

            return false;
        });
    },

    resetPassword: function () {
        $('body').on('submit','form.js-reset-password-form', function(e) {
            e.preventDefault();
            const form = $(this);
            const url = form.attr('action');
            const modalTitle = $(this).closest('.js-modal-reset').find('.js-modal-subtitle');

            handleAjax(url, form, 'post', function (data) {
                if (!data.success) {
                    formValidation(form, data);
                    alertMessage(data.errorMessage, 'cc-alert--danger');
                } else {
                    modalTitle.empty();
                    $('.request-password-body').empty().append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $('#submitEmailButton').text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                    } else {
                        $('.send-email-btn').empty().html('<a href="' + data.returnUrl + '" class="btn-block cc-btn cc-btn--primary">' + data.buttonText + '</a>');
                    }
                    $('body').spinner().stop();
                }
            }, function () {
                $('body').spinner().stop();
            });

            return false;
        });
    },

    clearResetForm: function () {
        $('body').on('hidden.bs.modal', '#login .modal',  function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }
};

$(() => {
    pwdToggler.initializeClass();
    setMaxDate();
    const modal = $('.js-loginModal');
    const buttonEye = $('.js-loginModal').find('.js-eye').first();
    const buttonSpan = $(buttonEye).find('span');
    const iconCalendar = $(modal).find('.js-calendar');
    const iconCalendarWrap = $(modal).find('.js-input-icon');
    setTimeout(function(){
        $('.js-form-select.selectpicker').selectpicker('refresh');
    },500);

    $('body').on('shown.bs.modal', '.js-loginModal', function (e) {
        const selectedUrl = $(this).data('href');
        $.ajax({
            url: selectedUrl,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                if (data.renderedTemplate){
                    $('.js-loginModal form').find('.js-eye-form').empty().html(buttonSpan);
                    $('.js-loginModal .cc-modal__dialog').empty().append(data.renderedTemplate);
                    modal.find('.cc-modal__close').appendTo(modal.find('.js-login'));
                    $(modal).addClass("pr-0");
                    const selectPickerElement = modal.find('.js-form-select.selectpicker');
                    selectPickerElement.find('option[value=""]').hide().parent().selectpicker('refresh');
                    pwdToggler.initializeClass();
                    setMaxDate();
                }
            },
            error: function () {
                console.error("error");
            }
        });
    });

    $('body').on('click', '.js-switch-to-register', function(){
        modal.find('.cc-modal__close').appendTo(modal.find('.js-register'));
        modal.attr('data-step', 'register');

        const inputDate =  $(modal).find('.js-register input[type="date"]');
        $(iconCalendar).removeClass('d-none');
        $(iconCalendarWrap).insertAfter(inputDate);
    });

    $('body').on('click', '.js-switch-to-login', function(e){
        e.preventDefault();
        modal.find('.cc-modal__close').appendTo(modal.find('.js-login'));
        modal.attr('data-step', 'login');
    });

    $('body').on('hide.bs.modal', "#loginModal", function (e) {
        modal.find('.cc-modal__close').appendTo(modal);
        modal.attr('data-step', 'login');
        $(modal).removeClass("pr-0");
    });

    $('.js-modal-reset').on('show.bs.modal', function () {
        $(this).addClass("pr-0");
    });

    $('.js-modal-reset').on('hide.bs.modal', function () {
        $(this).removeClass("pr-0");
    });

    $('.js-complete-profile-form .js-form-control').each(function() {
        if ($(this).val() !== '') {
            $(this).prop('disabled', true);
        }
    });

    if(window.localStorage.getItem("wasRegistered")) {
        const newUserMessageSuccess = $('.js-loginModal').data('new-user');
        alertMessage(newUserMessageSuccess, 'alert-success cc-alert--success');
        window.localStorage.removeItem("wasRegistered");
    }
});

const setMaxDate = () => {
    if($('.js-min-age-input').length > 0) {
        const today = new Date();
        const date = $('.js-min-age-input').attr('minlength');
        let eighteenYearsAgo = new Date(today.getFullYear() - date, today.getMonth(), today.getDate());
        const maxDate = eighteenYearsAgo.toISOString().split('T')[0];
        $('input[type="date"]').attr('max', maxDate);
    }
}