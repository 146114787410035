'use strict';

let base = require('./base');
let swatchSelected = '';

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
const parseHtml = (html) => {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
const fillModalElement = (selectedValueUrl) => {
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $('#quickViewModal .js-modal-body').empty().html(parsedHtml.body);
            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:shown');

            const $sliders = $('#quickViewModal').find('.js-slider');
            if ($sliders.length > 0) {
                $sliders.each((index, slider) => {
                    $(slider).not('.slick-initialized').slick();
                });
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    showQuickview: () => {
        $('body').on('click', '.quickview', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            const selectedValueUrl = $(this).closest('button.quickview').attr('data-href');
            swatchSelected = $(this).closest('.product').find('.js-swatch-color.selected').closest('a').data('attr-value');
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: () => {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            let modal = $('#quickViewModal');
            let quickviewSwatches = modal.find('.cc-quickview__color-swatch div');

            if (quickviewSwatches.length === 1) {
                $(quickviewSwatches).closest('.color-attribute').trigger('click');
            } else {
                quickviewSwatches.each(function (index, element) {
                    if ($(element).data('attr-value') === swatchSelected) {
                        $(element).closest('.color-attribute').trigger('click');
                    }
                });
            }
        });
    },
    onOtherModalsOpen: () => {
        $('body').on('show.bs.modal', '.modal:not(.js-quickview-modal)', function () {
            if ($('body').hasClass('cc-quickview--opened')) {
                $('#quickViewModal').modal('hide');
                new Promise(function (resolve) {
                    $('#quickViewModal').on('hidden.bs.modal', function () {
                        resolve();
                    });
                }).then(function () {
                    $('body').addClass('modal-open').removeClass('cc-quickview--opened');
                });
            }
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: () => {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: () => {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: () => {
        $('body').on('product:beforeAttributeSelect', function () {
            //$('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: () => {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            }
        });
    },
    updateAddToCart: () => {
        $('body').on('product:updateAddToCart', function (e, response) {
            $('button.add-to-cart-global', response.$productContainer).attr(
                'disabled',
                (!response.product.readyToOrder || !response.product.available)
            );

            var dialog = $(response.$productContainer)
                .find('.cc-quickview__dialog');

            $('.add-to-cart-global', dialog).attr(
                'disabled',
                (!response.product.readyToOrder || !response.product.available)
            );
        });
    },
    updateAvailability: () => {
        $('body').on('product:updateAvailability', function (e, response) {
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    },
    hideModal: () => {
        $('body').on('hidden.bs.modal', '#quickViewModal', (e) => {
            $('.js-omnibus-price').empty();
            $('#quickViewModal .js-modal-body').empty();
        });
    },
};