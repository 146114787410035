const constants = require('../constants');

/* Variabili globali */
window.isMobile = false;
window.isTablet = false;
window.isTabletPortrait = false;
window.isTabletLandscape = false;
window.isDesktop = false;

/* Funzioni Globali */
const setHeaderHeight = () => {
    if ($('.cc-header__main').length > 0) {
        const headerHeight = $(document).find('.cc-header__main').outerHeight(true);
        document.documentElement.style.setProperty('--headerHeight', headerHeight / 10 + 'rem');
    }
};

const setFooterHeight = () => {
    if ($('.cc-footer').length > 0) {
        const footerHeight = $(document).find('.cc-footer').outerHeight(true);
        document.documentElement.style.setProperty('--footerHeight', footerHeight / 10 + 'rem');
    }
};

const setFullHeight = () => {
    const windowHeight = $(window).innerHeight();
    document.documentElement.style.setProperty('--windowHeight', windowHeight / 10 + 'rem');
};

const responsiveResize = () => {
    getWindowSizes();
    setHeaderHeight();
    setFooterHeight();
    setFullHeight();
    getWindowBrowser();

    // Slider Init
    if ($('.js-slider').length > 0) {
        $('.js-slider').each(function () {
            $(this).not('.slick-initialized').slick();
        });
    }
};

/**
 * Get the window size and set the global variables
 */
const getWindowSizes = () => {
    if (window.matchMedia('(max-width: 767.98px)').matches) {
        $('body')
            .addClass('cc-mobile')
            .removeClass('cc-tablet')
            .removeClass('cc-desktop');
        window.isMobile = true;
        window.isTablet = false;
        window.isDesktop = false;
    } else if (window.matchMedia('(max-width: 1199.98px)').matches) {
        $('body')
            .removeClass('cc-mobile')
            .addClass('cc-tablet')
            .removeClass('cc-desktop');
        window.isMobile = false;
        window.isTablet = true;
        window.isDesktop = false;
    } else {
        $('body')
            .removeClass('cc-mobile')
            .removeClass('cc-tablet')
            .addClass('cc-desktop');
        window.isMobile = false;
        window.isTablet = false;
        window.isDesktop = true;
    }
}

/**
 * Get the browser and add a class to the body
 */
getWindowBrowser = () => {
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isOpera) {
        $('body').addClass('cc-opera');
    } else if (isFirefox) {
        $('body').addClass('cc-firefox');
    } else if (isSafari) {
        $('body').addClass('cc-safari');
    } else if (isIE) {
        $('body').addClass('cc-ie');
    } else if (isEdge) {
        $('body').addClass('cc-edge');
    } else if (isEdgeChromium) {
        $('body').addClass('cc-edgeChromium');
    } else if (isChrome) {
        $('body').addClass('cc-chrome');
    } else if (isBlink) {
        $('body').addClass('cc-blink');
    } else {
        $('body').addClass('cc-unknown-browser');
    }
}

window.AOS = require('aos');

$(() => {
    responsiveResize();
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    // Select
    if ($('.js-form-select.selectpicker').length > 0) {
        if(window.isDesktop) {
            $('.js-form-select.selectpicker').each(function () {
                $(this).selectpicker('refresh');
                $(this).find('option[value=""]').hide().closest('select').selectpicker('refresh');
                const label = $(this).closest('.cc-form__wrap').find('.cc-form__label').html();
                const selectId = $(this).attr('id');
                const htmlString = '<label class="cc-form__label cc-visibility-hidden" for="button-' + selectId + '">' + label + '</label>';

                $(this).closest('.js-form-select').find('.dropdown-toggle').attr('aria-label', label);
                $(this).closest('.js-form-select').find('.dropdown-toggle').attr('aria-controls', 'button-' + selectId);
                $(this).closest('.js-form-select').find('.dropdown-menu > .inner').attr('id', 'button-' + selectId);
                $(this).closest('.js-form-select').find('.dropdown-menu').prepend(htmlString);
            });
        }
    }

    // Accessibility for slider
    if ($('.js-slider').not('.slick-initialized').length > 0) {
        $('.js-slider').not('.slick-initialized').each(function () {
            $(this).children().removeAttr('aria-hidden tabindex role aria-describedby');
        });
    }
    if ($('.js-slider.slick-initialized .js-tile-url').length > 0) {
        $('.js-slider.slick-initialized .js-tile-url').each(function () {
            $(this).attr('tabindex', '-1');
        });
    }

    // Prevent click on swipe
    $('.js-slider.slick-initialized').each(function () {
        let isSliding = false;
        $(this).on('beforeChange', function() {
            isSliding = true;
        });
        $(this).on('afterChange', function() {
            isSliding = false;
        });
        $(this).find('a').off('click').on('click', function(e) {
            if (isSliding) {
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                return;
            }
        });
    });

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Data AOS
    AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 300, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 1000, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
    });
    // refresh AOS after images with a lazy loading are loaded
    $('img.cc-imgLazy').on('load', () => AOS.refresh());

    $(window).on('resize', () => {
        responsiveResize();
    });
});

/**
 * Display an alert message
 * @param {String} $text - Text to display
 * @param {String} $class - Class to add to the alert
 */
const alertMessage = ($text, $class) => {
    const successTimeout = 3000;
    const dangerTimeout = 6000;
    let timeout = successTimeout;

    if ($text && $text!=""){
        $('.js-alert-message').empty().text($text);

        $('.js-alert').addClass($class).removeClass('cc-active').addClass('cc-active');

        if ($('.js-alert').hasClass('cc-alert--danger')) {
            timeout = dangerTimeout;
        }
    
        setTimeout(() => {
            $('.js-alert').removeClass($class).removeClass('cc-active');
        }, timeout);
    }

    $('.js-close-alert').off('click').on('click', () => {
        $('.js-alert').removeClass($class).removeClass('cc-active');
    });
}

module.exports = {
    setHeaderHeight: setHeaderHeight,
    getWindowSizes: getWindowSizes,
    alertMessage: alertMessage
};
