'use strict';

const SELECTORS = {
    element: '.js-hotspot',
    button: '.js-hotspot-btn',
    card: '.js-hotspot-card',
    showCoordinates: '.js-show-coordinates'
};

const CLASSES = {
    active: 'cc-active'
};

class Hotspot {
    constructor(element) {
        this.element = element;
        this.button = element.find(SELECTORS.button);
        this.card = element.find(SELECTORS.card);

        this.initEvents();
    }

    initEvents() {
        this.button.on('click', this.toggleCard.bind(this));
        this.onDocumentClick();
        this.onShowCoordinatesClick();
    }

    onDocumentClick() {
        $(document).on('click', (e) => {
            const container = $(SELECTORS.card);
            const hotspotBtn = $(SELECTORS.button);
            if ((!container.is(e.target) && container.has(e.target).length === 0) && !hotspotBtn.is(e.target)) {
                container.removeClass(CLASSES.active);
            }
        });
    }

    toggleCard() {
        $(SELECTORS.card).removeClass(CLASSES.active);
        this.card.toggleClass(CLASSES.active);
    }

    onShowCoordinatesClick() {
        $(SELECTORS.showCoordinates).off('click').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            const clickX = e.pageX;
            const clickY = e.pageY;
            const wrpX = $(this).offset().left;
            const wrpY = $(this).offset().top;
            const wrpWidth = $(this).outerWidth();
            const wrpHeight = $(this).outerHeight();
            const percentageY = ((clickY - wrpY) / wrpHeight) * 100;
            const percentageX = ((clickX - wrpX) / wrpWidth) * 100;
            alert('X: ' + percentageX.toFixed(3) + '%\nY: ' + percentageY.toFixed(3) + '%');
        });
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new Hotspot($(element));
    });
};