'use strict';

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target, type) {
    const imageURL = $('#dataSpinner').data('url');
    const altText = $('#dataSpinner').data('alt');
    const spinnerType = type;

    const $imageElement = $('<img>').attr({
        class: 'cc-imgLazy',
        src: imageURL,
        loading: 'lazy',
        width:'150',
        height:'150',
        alt: altText
    });
    const $veil = $('<div class="veil cc-veil js-veil"><div class="cc-underlay underlay"></div></div>');
    $veil.append(`<div class="spinner cc-spinner"></div>`);
    if (spinnerType === 'loader') {
        $veil.append(`<div class="cc-spinner__loader"><div class="cc-spinner__loader__active"></div></div>`);
    }
    $veil.find('.cc-spinner').append($imageElement);
    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled cc-veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.spinner').css('position', 'fixed');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    const $element = $(this);
    const Fn = function () {
        this.start = function () {
            if ($element.length) {
                addSpinner($element);
            }
        };
        this.stop = function () {
            if ($element.length) {
                const $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    const Fn = function () {
        this.start = function (type = 'default') {
            addSpinner($('body'), type);
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
