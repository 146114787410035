'use strict'

const {setHeaderHeight} = require('../common/common')
const login = require('../login/login')
const { accessibleModal } = require('../utilities/accessibility')

const SELECTORS = {
    element: '.js-header-wrap',
    itemNavigation: '.js-header-item',
    itemButton: '.js-header-btn',
    submenu: '.js-header-megamenu',
    backButton: '.js-header-backbutton',
    mobileMenu: '.js-mobile-menu',
    openMobileMenu: '.js-header-openmenu',
    loginButton: '.js-step-login',
    minicartModal: '#minicartModal',
    linksToToggleActive: '.js-link'
}

const CLASSES = {
    open: 'cc-header__megamenu--open',
    mobileOpen: 'cc-mobilemenu--open',
    bodyClassLogin: 'js-page-registration',
    active: 'cc-active'
}

/**
 * Header class that handles the header navigation
 */
class Header {
    constructor(element) {
        this.element = element;
        this.itemNav = element.find(SELECTORS.itemNavigation);
        this.itemBtn = element.find(SELECTORS.itemButton);
        this.submenu = element.find(SELECTORS.submenu);
        this.itemBackButton = element.find(SELECTORS.backButton);
        this.itemOpenMenu = $(SELECTORS.openMobileMenu);
        this.mobileMenu = $(SELECTORS.mobileMenu);
        this.loginButton = $(SELECTORS.loginButton);
        this.minicartModal = $(SELECTORS.minicartModal);
        this.bodyClassLogin = $(SELECTORS.bodyClassLogin);
        this.links = $(SELECTORS.linksToToggleActive);
        this.currentLevel = 0;
        this.submenuStack = [];
        this.initEvents();
        this.checkActiveStateFromURL();
        setHeaderHeight();
    }

    initEvents() {
        this.toggleAccessibilityFeatures();

        this.itemOpenMenu.on('click', () => {
            this.toggleMenuMobile();
        })

        this.loginButton.on('click', () => {
            this.closeModalOnLoginPage();
        })

        this.itemBtn.on('click keydown', (e) => {
            const subMenu = $(e.currentTarget).next(SELECTORS.submenu);
            if (!subMenu.length) return;

            if ((e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown' || e.type === 'mouseover') && window.isDesktop) {
                e.preventDefault();
                subMenu.addClass(CLASSES.open);
                subMenu.attr('aria-hidden', 'false');
                subMenu.removeAttr('tabindex');
            } else if ((e.type === 'click' || e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowRight') && !window.isDesktop) {
                e.preventDefault();
                subMenu.addClass(CLASSES.open);
                this.submenuStack.push(subMenu);
            }
        });

        this.itemNav.on('keydown', (e) => {
            if (e.key === 'Escape' && window.isDesktop) {
                e.preventDefault();
                const submenu = $(e.currentTarget).hasClass(CLASSES.open) ? $(e.currentTarget) : $(e.currentTarget).closest('.cc-header__megamenu--open');
                $(submenu).removeClass(CLASSES.open);
                $(submenu).attr('aria-hidden', 'true');
                $(submenu).attr('tabindex', '-1');
                $(submenu).closest(SELECTORS.itemNavigation).children('a').trigger('focus');
            }
        });

        this.itemBtn.on('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
                e.preventDefault();
                e.stopPropagation();
                var subMenu = $(e.currentTarget).next(SELECTORS.submenu)
                if ((subMenu.length > 0) && window.isDesktop) {
                    subMenu.addClass(CLASSES.open);
                    $(e.currentTarget).attr('aria-hidden', 'false');
                    $(e.currentTarget).removeAttr('tabindex');
                }
            }
        });

        this.submenu.on('keydown mouseleave', (e) => {
            if ((e.key === 'Escape' || e.type === 'mouseleave') && window.isDesktop) {
                e.preventDefault();
                e.stopPropagation();
                const submenu = $(e.currentTarget).hasClass(CLASSES.open) ? $(e.currentTarget) : $(e.currentTarget).closest('.cc-header__megamenu--open');
                $(submenu).removeClass(CLASSES.open);
                $(submenu).attr('aria-hidden', 'true');
                $(submenu).attr('tabindex', '-1');
                $(submenu).closest(SELECTORS.itemNavigation).children('a').trigger('focus');
            }
        });

        this.itemBackButton.on('click', (e) => {
            e.preventDefault();
            const previousSubMenu = this.submenuStack.pop();
            if (previousSubMenu) {
                previousSubMenu.removeClass(CLASSES.open);
            }
        });

        this.mobileMenu.on('keydown', (e) => {
            if (e.key === 'Escape' && !window.isDesktop) {
                this.toggleMenuMobile();
            }
        });

        $(window).on('resize', () => {
            this.toggleAccessibilityFeatures();
        });
    }

    /**
     * Toggle accessibility features. If the user is on mobile, we will add the modal accessibility features to the menu and its subpanels 
     */
    toggleAccessibilityFeatures() {
        accessibleModal(this.loginButton);

        if (window.isDesktop) return;

        this.mobileMenu.attr('role', 'dialog');
        this.mobileMenu.attr('aria-modal', 'true');
        this.mobileMenu.attr('aria-hidden', 'true');
        this.mobileMenu.attr('tabindex', '-1');
        this.mobileMenu.attr('aria-labelledby', 'mobile-menu-trigger');

        accessibleModal(this.itemOpenMenu);

        if (this.itemBtn.length > 0) {
            this.itemBtn.each((index, element) => {
                accessibleModal($(element));
            });
        }
    }

    /**
     * Toggle mobile menu 
     */
    toggleMenuMobile() {
        $('body').toggleClass('cc-body-no-scroll');
        this.mobileMenu.toggleClass(CLASSES.mobileOpen);
        this.itemOpenMenu.toggleClass(CLASSES.mobileOpen);
        if (!this.mobileMenu.hasClass(CLASSES.mobileOpen)) {
            this.submenuStack = [];
            $(SELECTORS.submenu).removeClass(CLASSES.open);
        }
        this.itemOpenMenu.attr('aria-expanded', this.mobileMenu.hasClass(CLASSES.mobileOpen));
    }

     /**
     * Close login modal on login page 
     */
    closeModalOnLoginPage() {
        if (window.isMobile) {
            if($('body').hasClass((CLASSES.bodyClassLogin))){
                this.mobileMenu.removeClass(CLASSES.mobileOpen);
                this.itemOpenMenu.removeClass(CLASSES.mobileOpen);
                this.itemOpenMenu.attr('aria-expanded', this.mobileMenu.hasClass(CLASSES.mobileOpen));
                $('body').removeClass('cc-body-no-scroll');
            }
        } 
    }
    
    /**
     * Check the current URL and add the `active` class to the corresponding menu item.
     */
    checkActiveStateFromURL() {
        const currentURL = window.location.href;
        this.links.each((index, link) => {
            const href = $(link).attr('href');
            if (href && currentURL.includes(href)) {
                $(link).addClass(CLASSES.active).attr('aria-current', 'page');
            }
        });
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new Header($(element));
    });
}

