const SELECTORS = {
    siteLogo: '#logo-link',
    tabPanel: {
        tabpanel: '[role="tabpanel"]',
        tablist: '[role="tablist"]',
        tabs: '.js-accessible-tab',
        tabwrp: '.js-accessible-tabs-wrp'
    },
    modal: {
        trigger: '.js-open-accessible-dialog',
        target: '.js-accessible-dialog',
        closeButton: '.js-close-accessible-dialog'
    }
};

/**
 * This function handles opening of an accessible modal.
 * @param {*} modal - the modal element
 * @param {*} firstFocusable - the first focusable element inside the modal
 */
function openModal(modal, firstFocusable) {
    // need to wait some time since some modals are hidden with display: none
    setTimeout(function () {
        modal.attr('aria-hidden', 'false');
        modal.attr('aria-modal', 'true');
        firstFocusable.focus();
    }, 200);
}

/**
 * This function handles closing of an accessible modal.
 * @param {*} modal - the modal element
 * @param {*} openBtn - the button that triggers the opening of the modal
 */
function closeModal(modal, openBtn) {
    modal.attr('aria-hidden', 'true');
    modal.attr('aria-modal', 'false');
    openBtn.focus();
}

/**
 * This function handles the functions to trigger after a modal is closed or it's opened. It's used to handle the focus on the modal elements.
 * It adds event listeners on the elements that trigger the open/close functionality of the modal.
 * It only handles the accessible features, so to toggle the visibility of the modal a different logic should be implemented.
 * This logic follows the ARIA Dialog pattern @see https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/examples/dialog/
 * @param {HTMLButtonElement} $openBtn - the button that triggers the opening of the modal
 */
function accessibleModal($openBtn) {
    var $modal = $($openBtn.data('target'));
    var $closeBtn = $($modal.find(SELECTORS.modal.closeButton));
    var focusableElements = $modal.find('a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])');
    var $firstFocusable = focusableElements.first();
    var $lastFocusable = focusableElements.last();

    $openBtn.on('click keydown', function (evt) {
        if (evt.type === 'keydown' && evt.key !== 'Enter' && evt.key !== ' ') {
            return;
        }

        if ($openBtn.attr('aria-expanded') === 'true') {
            closeModal($modal, $openBtn);
        } else {
            openModal($modal, $firstFocusable);
        }
    });

    $closeBtn.on('click', function () {
        closeModal($modal, $openBtn);
    });

    $modal.on('keydown', function (e) {
        if (e.key === 'Escape') {
            closeModal($modal, $openBtn);
        }

        if (e.key === 'Tab') {
            if (e.shiftKey) {
                if (document.activeElement === $firstFocusable[0]) {
                    e.preventDefault();
                    $lastFocusable[0].focus();
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (document.activeElement === $lastFocusable[0]) {
                    e.preventDefault();
                    $firstFocusable[0].focus();
                }
            }
        }
    });
}

/**
 * This function handles the accessibility logic used on tablist elements.
 * It only handles the accessible features, so to toggle the visibility of the different tab panels a different logic should be implemented.
 * This logic follows the ARIA Tabs pattern @see https://www.w3.org/WAI/ARIA/apg/patterns/tabpanel/
 * @param {HTMLButtonElement} dialogTriggerButton - the button that triggers the opening of the modal
 */
function accessibleTablist() {
    // Handles the arrow navigation on the tab panel elements and triggers the different panels with the "Enter/Space/Click" events.
    $(SELECTORS.tabPanel.tabs).on('keydown click shown.bs.tab', (evt) => {
        const $parentTablist = $(evt.currentTarget).closest(SELECTORS.tabPanel.tablist);
        const tabsNodeList = $parentTablist[0].querySelectorAll(SELECTORS.tabPanel.tabs);
        const tabsList = Array.prototype.slice.call(tabsNodeList);
        const firstTab = tabsList[0];
        const lastTab = tabsList[tabsList.length - 1];
        const thisTab = evt.currentTarget;
        const thisTabIndex = tabsList.indexOf(thisTab);
        const nextTab = tabsList[thisTabIndex + 1];
        const previousTab = tabsList[thisTabIndex + -1];
        const thisTabWrp = $(thisTab).closest(SELECTORS.tabPanel.tabwrp);
        const thisTabPanel = '#' + $(thisTab).attr('aria-controls');

        if (evt.key === 'Enter' || evt.key === ' ' || evt.type === 'shown' || evt.type === 'click') {
            $(SELECTORS.tabPanel.tabs).each((index, element) => {
                const $tabEl = $(element);
                $tabEl.attr('aria-selected', 'false');
                $tabEl.attr('tabindex', '-1');
            });
            $(thisTab).attr('aria-selected', 'true');
            $(thisTab).attr('tabindex', '0');
            if (evt.type !== 'click') {
                $(thisTab).tab('show');
            }
            $(thisTabWrp).find(SELECTORS.tabPanel.tabs).removeClass('active');
            $(thisTab).addClass('active');
            $(thisTabWrp).find(SELECTORS.tabPanel.tabpanel).removeClass('active').attr('aria-hidden', true);
            $(thisTabPanel).addClass('active').attr('aria-hidden', false);
        } else if (evt.key === 'ArrowRight' || evt.key === 'ArrowLeft') {
            if ($parentTablist.attr('aria-orientation') === 'horizontal' || !$parentTablist.attr('aria-orientation')) {
                evt.preventDefault();
                if (evt.key === 'ArrowRight') {
                    if (thisTab === lastTab) {
                        firstTab.focus();
                    } else {
                        nextTab.focus();
                    }
                } else if (evt.key === 'ArrowLeft') {
                    if (thisTab === firstTab) {
                        lastTab.focus();
                    } else {
                        previousTab.focus();
                    }
                }
            }
        } else if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
            if ($parentTablist.attr('aria-orientation') === 'vertical') {
                evt.preventDefault();
                if (evt.key === 'ArrowDown') {
                    if (thisTab === lastTab) {
                        firstTab.focus();
                    } else {
                        nextTab.focus();
                    }
                } else if (evt.key === 'ArrowUp') {
                    if (thisTab === firstTab) {
                        lastTab.focus();
                    } else {
                        previousTab.focus();
                    }
                }
            }
        }
    });
}

/**
 * This function handles the focus on the first focusable element inside a section.
 * It's used to handle the focus on the first focusable element when a section is opened.
 * @param {JQuery} $section - the section to focus on
 */
function focusOnSection($section) {
    var focusableElementsSelector = 'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';
    var $focusableElements = $section.find(focusableElementsSelector);

    if ($focusableElements.length) {
        var $firstFocusableElement = $focusableElements.first();
        $firstFocusableElement.trigger('focus');
    }
}

module.exports = {
    accessibleModal,
    accessibleTablist,
    focusOnSection
};
