'use strict';

const debounce = require('lodash.debounce');

const SELECTORS = {
  element: '.js-customer-nav',
  activeLink: '.js-link.cc-active'
};

/**
 * Class to dynamically center an active element inside a container.
 */
class AnchorManager {
  constructor() {
    this.activeElement = $(SELECTORS.activeLink);
    this.container = $(SELECTORS.element);
    this.init();
    this.anchorIntoView();
  }

  init() {
    const debouncedAnchorIntoView = debounce(() => {
      this.anchorIntoView();
    }, 200);

    $(window).on('resize', debouncedAnchorIntoView);
  }

  anchorIntoView() {
    if ($(window).width() > 1024) {
      this.container.scrollLeft(0);
      return;
    }

    if (this.activeElement.length === 0) return;

    const elementOffset = this.activeElement.offset().left;
    const containerScrollLeft = this.container.scrollLeft();
    const containerWidth = this.container.outerWidth(true);
    const elementWidth = this.activeElement.outerWidth(true);
    const targetScroll = (elementOffset + containerScrollLeft) - (containerWidth / 2) + (elementWidth / 2);

    this.container.animate({
      scrollLeft: targetScroll
    }, 500, 'swing');
  }
}

module.exports = () => {
  new AnchorManager();
};
