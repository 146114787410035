'use strict';

const SELECTORS = {
    element: '.js-accordion',
    target: '.js-accordion-target',
    trigger: '.js-accordion-trigger',
};

const CLASSES = {
    active: 'cc-active',
}

/**
 * Accordion class that handles the accordion components
 */
class Accordion {
    constructor(element) {
        this.element = element;
        this.trigger = element.find(SELECTORS.trigger);
        const targetSelector = this.trigger.attr('aria-controls');
        this.target = $('#' + targetSelector);

        this.initEvents();
    }

    initEvents() {
        this.trigger.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggleAccordion();
        });
    }

    /**
     * Toggles the accordion by adding/removing the active class
     * Also changes the ARIA attributes of the elements inside the accordion
     */
    toggleAccordion() {
        this.element.toggleClass(CLASSES.active);

        const isActive = this.element.hasClass(CLASSES.active);
        this.target.attr('aria-hidden', !isActive);
        this.target.attr('tabindex', isActive ? '0' : '-1');
        this.trigger.attr('aria-expanded', isActive);
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new Accordion($(element));
    });
};
