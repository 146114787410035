'use strict';

const { alertMessage } = require('../common/common');

module.exports = function () {
    $('.trackorder').on('submit', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form = $(this);
        var url = $form.attr('action');
        var data = $form.serialize();
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: function (data) {
                if (data.success) {
                    window.location.href = data.urlRedirect;
                } else {
                    $.spinner().stop();
                    alertMessage(data.msg, 'cc-alert--danger');
                }
            },
            error: function (err) {
                alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                console.error(err);
                $.spinner().stop();
            }
        });
    });
};
