const { availability } = require("../product/base");

module.exports = {
    SELECTORS: {
        element: '.js-cart',
        cartError: '.js-cart-error',
        minicartExclusion: '.js-cart:not(.js-minicart)',
        quantity: '.js-quantity',
        page: '.js-page',
        moveToWishlist: '.js-move-to-wishlist',
        edit: '.js-edit-product-modal',
        removeProduct: '.js-remove-product',
        minicart: '.js-minicart',
        minicartQty: '.js-minicart-qty',
        minicartLink: '.js-minicart-link',
        editProductModal: '#editProductModal',
        bonusProductButton: '.bonus-product-button',
        chooseBonusProductModal: '#chooseBonusProductModal',
        changeQty: '.js-change-qty',
        couponsAndPromo: '.js-coupons-and-promos',
        bonusProduct: '.bonus-product',
        approachingDiscounts: '.approaching-discounts',
        shippingCost: '.js-shipping-cost',
        coupon3050Total: '.js-coupon-3050-total',
        couponSFCCTotal: '.js-coupon-sfcc-total',
        coupon3050Label: '.js-coupon-3050',
        couponSFCCLabel: '.js-coupon-sfcc',
        taxTotal: '.js-tax-total',
        grandTotal: '.js-grand-total',
        subTotal: '.sub-total',
        showBtnPPExpress: '.btn-pp-express-show',
        showBtnPPExpressMiniCart: '.btn-pp-express-show-minicart',
        orderDiscountLabel: '.js-order-discount',
        orderDiscountTotal: '.js-order-discount-total',
        shippingDiscountLabel: '.js-shipping-discount',
        shippingDiscountTotal: '.js-shipping-discount-total',
        checkoutBtn: '.js-checkout-btn',
        checkoutBtnModal: '.js-checkout-btn-modal',
        globalAvailability: '.global-availability',
        updateCartProductGlobal: '.update-cart-product-global',
        productAvailability: '.product-availability',
        availabilityMsg: '.global-availability .availability-msg',
        optionsSelect: '.options-select',
        quickViewDialog: '.quick-view-dialog',
        promoForm: '.promo-code-form',
        couponCodeInput: '#couponCode',
        removeCoupon: '.remove-coupon',
        deleteCouponConfirmation: '.delete-coupon-confirmation-btn',
        proSubscription: '.js-pro-subscription-cart',
        proCouponBtn: '.js-pro-coupon',
        resendEmail: '.js-resend-email',
        proCouponConfirm: '.js-pro-coupon-confirm',
        proCouponModal: '#couponCodeModal',
        cartFinalSubtotal: '.js-final-subtotal',
        cartFixedButton: '.js-cart-fixed-button'
    },
    CLASSES: {
        emptyCart: 'cc-cart--empty',
        launchedModal: 'launched-modal',
        disabled: 'disabled cc-disabled',
        minicartEmpty: 'cc-minicart--empty',
        hide: 'd-none',
        couponApplied: 'cc-promo-code-form--applied',
        removeSub: 'js-remove-product-subscription'
    },
    EVENTS: {
        cartBeforeUpdate: 'cart:beforeUpdate',
        updateAddToCart: 'product:updateAddToCart',
        setShippingMethodSelection: 'setShippingMethodSelection',
        minicartRefresh: 'minicart:refresh',
        cartUpdate: 'cart:update',
        editModalReady: 'editproductmodal:ready',
        updateAvailability: 'product:updateAvailability',
        afterAttributeSelect: 'product:afterAttributeSelect',
        afterAddToCart: 'product:afterAddToCart',
        afterEditItem: 'product:afterEditItem',
    }
};
