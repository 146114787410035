'use strict';

const cart = require('../cart/cart');

const SELECTORS = {
    element: '.js-minicart',
    modal: '#minicartModal',
    modalContent: '.js-minicart-modal',
    proSubscription: '.js-pro-subscription',
    loginModal: '.js-loginModal',
    loginCta: '.js-step-login',
    quantity: '.js-minicart-qty',
    link: '.js-minicart-link',
    fercamModal: '#fercamCheckoutModal',
    payBtn: '.js-paypal-button-on-cart-page'
}

/**
 * Minicart component
 * @param {HTMLElement} element - The minicart element
 * @constructor
 * @returns {Object} - The minicart instance
 */
class Minicart {
    constructor(element) {
        this.updateMiniCart = true;

        this.element = element;
        this.quantity = element.find(SELECTORS.quantity);
        this.loginModal = $(SELECTORS.loginModal);
        this.link = $(SELECTORS.link);
        this.modal = $(SELECTORS.modal);
        this.modalContent = this.modal.find(SELECTORS.modalContent);

        this.init();
        this.checkSubscriptionFlow();
    }

    /**
     * Load minicart content and open the modal if open param is true
     * @memberof Minicart
     * @method loadMinicartContent
     * @public
     * @param {Boolean} open - Open the minicart modal
     * @returns void
     */
    loadMinicartContent(open = false) {
        const url = this.element.data('action-url');

        if (!this.modal.hasClass('show') && open) {
            if (!this.updateMiniCart) {
                this.modal.modal('show');
                return;
            }
            this.modal.modal('show');
            $.get(url, (data) => {
                this.modalContent.empty().append(data);
                this.updateMiniCart = false;
                this.element.trigger('minicart:contentLoaded');
                cart();
                this.initPaypalButton();
            });
            return;
        }

        if (this.updateMiniCart) {
            $.get(url, (data) => {
                this.modalContent.empty().append(data);
                this.updateMiniCart = false;
                this.element.trigger('minicart:contentLoaded');
                cart();
                this.initPaypalButton();
            });
        }

        $.spinner().stop();
    }

    /**
     * Initialize the minicart component
     * @memberof Minicart
     * @method init
     * @public
     */
    init() {
        this.element.on('count:update', (event, count) => {
            if (count && !Number.isNaN(count.quantityTotal)) {
                this.quantity.css('display', () => count.quantityTotal === 0 ? 'none' : 'inline-flex');
                this.quantity.text(count.quantityTotal);
                this.link.attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            }
        });
        this.element.on('click', () => {
            this.loadMinicartContent(true);
        });
        this.element.on('mouseenter', () => {
            this.loadMinicartContent(false);
        });
        $('body').on('product:afterAddToCart', () => {
            this.updateMiniCart = true;
            this.loadMinicartContent(false);
        });
        $('body').on('minicart:refresh', () => {
            this.updateMiniCart = true;
            this.loadMinicartContent(true);
        });
        $('body').on('cart:update', () => {
            this.updateMiniCart = true;
        });
        $('body').on('show.bs.modal', this.loginModal, () => {
            this.updateMiniCart = true;
            this.modal.modal('hide');
        });
        this.element.on('minicart:contentLoaded', () => {
            this.onShowMinicartModal();
        });
        $(SELECTORS.fercamModal).on('hidden.bs.modal', () => {
            if ($('.cart-page.js-cart').length > 0) {
                return;
            }

            this.loadMinicartContent(true);
        });
    }

    /**
     * Show minicart modal
     * @memberof Minicart
     * @method onShowMinicartModal
     * @public
     */
    onShowMinicartModal () {
        sessionStorage.setItem('subscriptionFlow', JSON.stringify({
            loginStep: false,
            cartStep: false
        }));

        this.proSubscription = this.modal.find(SELECTORS.proSubscription);
        this.loginCta = this.modal.find(SELECTORS.loginCta);

        this.proSubscription.off('click').on('click', (e) => {
            $.spinner().start();
            const url = $(e.currentTarget).attr('data-action');
            $.ajax({
                url: url,
                method: 'POST',
                data: '',
                success: () => {
                    $('body').trigger('minicart:refresh');
                },
                error: (err) => {
                    console.error(err);
                    $.spinner().stop();
                }
            });
        });

        this.loginCta.off('click').on('click', () => {
            sessionStorage.setItem('subscriptionFlow', JSON.stringify({
                loginStep: false,
                cartStep: true
            }));
        });
    }

    /**
     * Check subscription flow
     * @memberof Minicart
     * @method checkSubscriptionFlow
     * @public
     * @returns void
     */
    checkSubscriptionFlow () {
        if (sessionStorage.getItem('subscriptionFlow')) {
            const subscriptionFlow = JSON.parse(sessionStorage.getItem('subscriptionFlow'));
            if (subscriptionFlow && (!subscriptionFlow.cartStep || !subscriptionFlow.loginStep)) {
                sessionStorage.setItem('subscriptionFlow', JSON.stringify({
                    loginStep: false,
                    cartStep: false
                }));
                return;
            }
            this.element.trigger('click');
        }
    }

    /**
     * Initialize paypal button
     * @memberof Minicart
     * @method initPaypalButton
     * @public
     * @returns void
     */
    initPaypalButton(){
        var PayPalCartModel = require('paypal/models/buttons/payPalCart');
        var payPalCartInstance = new PayPalCartModel(SELECTORS.payBtn);
        payPalCartInstance.initPayPalButton();
        return;
    }
}

module.exports = () => {
    cart();
    new Minicart($(SELECTORS.element));
};
