'use strict'

var mobileCart = {
    init: function () {
        if($('body > .js-loyaltyApp-dataset').data('loyaltyapp')) {
            mobileCart.sendEvent();
        }
    },
    sendEvent: function() {
        
        var items_count = $('.cc-header__minicart-quantity').text();
        let msg = +items_count

        var data = {
            items_count : msg ? msg : 0
        }
        try{
            if(window.flutter_inappwebview)
                window.flutter_inappwebview.callHandler('cart_event_handler', data);
        } catch (e) {
            console.error("Impossbile to execute firebase call handler!");
        }
    }

}

module.exports = mobileCart;