'use strict';

const { alertMessage } = require('../common/common');

const SELECTORS = {
    element: '.js-pickup-autocomplete',
    storesList: '.js-pickup-stores-list',
    useCurrentLocation: '.js-use-customer-location',
    selectStoreBtn: '.js-select-store',
    storeItem: '.js-store-item',
    selectedStoreInput: 'input#selectedStore',
    selectedStoreName: 'input#selectedStoreName',
    selectedStoreEmail: 'input#selectedStoreEmail',
    storeCard: '.js-store-card',
    storeCardName: '.js-store-card-name',
    storeCardAddress: '.js-store-card-address',
    storeCardPhone: '.js-store-card-phone',
    shippingStoreBlock: '.js-shipping-store-block',
    defaultLat: '#defaultLat',
    defaultLng: '#defaultLng',
    pickupStoreSearch: '#pickupStoreSearch'
};

/**
 * PickupInStore class that handles the address autocomplete components
 */
class PickupInStore {
    constructor(element) {
        this.element = element;
        this.form = this.element.closest('form');
        this.useCurrentLocation = this.form.find(SELECTORS.useCurrentLocation);
        this.storesList = $(SELECTORS.storesList);
        this.autocomplete = null;
        this.nodeElement = element[0];
        this.init();
    }

    /**
     * Initialize the address autocomplete component
     * @memberof PickupInStore
     * @method init
     * @public
     * @returns {void} 
     */
    init() {
        if (!window.google) {
            console.error('Google Maps JavaScript API not loaded.');
            this.setupFormSubmitWithoutAutocomplete();
            return;
        }

        const googleApisAutocomplete = this.getGoogleApiKey();
        if (googleApisAutocomplete && googleApisAutocomplete.trim() !== 'null') {
            this.isGoogleApiKeyValid(googleApisAutocomplete, (isValid) => {
                if (isValid) {
                    this.initializeAutocomplete();
                } else {
                    console.error('Google API Key is not valid for Places Autocomplete. Autocomplete is disabled.');
                    this.setupFormSubmitWithoutAutocomplete();
                }
            });
        } else {
            console.error('Google API Key is null or not available. Autocomplete is disabled.');
            this.setupFormSubmitWithoutAutocomplete();
        }

        $(SELECTORS.pickupStoreSearch).on('keydown', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const defaultLat = parseFloat($(SELECTORS.defaultLat).val());
                const defaultLng = parseFloat($(SELECTORS.defaultLng).val());
                this.performAjaxRequest(defaultLat, defaultLng);
            }
        });

        $('body').on('click', SELECTORS.selectStoreBtn, (evt) => {
            evt.preventDefault();
            this.selectStoreHandler(evt.currentTarget);
        });
    }

    /**
     * Get Google API Key
     * @memberof PickupInStore
     * @method getGoogleApiKey
     * @public
     * @returns {string} - Google API Key
     */
    getGoogleApiKey() {
        let googleApisAutocomplete = null;
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src.includes("maps.googleapis.com")) {
                const src = scripts[i].src;
                const apiKeyIndex = src.indexOf("key=");
                if (apiKeyIndex !== -1) {
                    googleApisAutocomplete = src.substring(apiKeyIndex + 4).split("&")[0];
                    break;
                }
            }
        }
        return googleApisAutocomplete;
    }

    /**
     * Check if Google API Key is valid
     * @memberof PickupInStore
     * @method isGoogleApiKeyValid
     * @param {String} apiKey - Google API Key
     * @param {Function} callback - Callback function
     */
    isGoogleApiKeyValid(apiKey, callback) {
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: 'test' }, function (predictions, status) {
            callback(status === google.maps.places.PlacesServiceStatus.OK);
        });
    }

    /**
     * Setup form submit without autocomplete
     * @memberof PickupInStore
     * @method setupFormSubmitWithoutAutocomplete
     * @public
     * @returns {void}
     */
    setupFormSubmitWithoutAutocomplete() {
        this.form.on('submit', (e) => {
            e.preventDefault();
            const defaultLat = parseFloat($(SELECTORS.defaultLat).val());
            const defaultLng = parseFloat($(SELECTORS.defaultLng).val());
            this.performAjaxRequest(defaultLat, defaultLng);
        });

        $(SELECTORS.pickupStoreSearch).on('keydown', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const defaultLat = parseFloat($(SELECTORS.defaultLat).val());
                const defaultLng = parseFloat($(SELECTORS.defaultLng).val());
                this.performAjaxRequest(defaultLat, defaultLng);
            }
        });

        this.useCurrentLocation.on('click', (e) => {
            e.preventDefault();
            this.useCurrentLocationHandler();
        });
    }

    /**
     * Initialize the address autocomplete
     * @memberof PickupInStore
     * @method initializeAutocomplete
     * @public
     * @returns {void}
     */
    initializeAutocomplete() {
        if (!this.nodeElement) {
            console.error(`No element found.`);
            return;
        }

        this.autocompleteField(this.nodeElement);

        this.form.on('submit', (e) => {
            e.preventDefault();
            const address = this.nodeElement.value;
            this.getLatLng(address);
        });

        $(SELECTORS.pickupStoreSearch).on('keydown', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const address = this.nodeElement.value;
                this.getLatLng(address);
            }
        });

        this.useCurrentLocation.on('click', (e) => {
            e.preventDefault();
            this.useCurrentLocationHandler();
        });
    }

    /**
     * Autocomplete field
     * @memberof PickupInStore
     * @method autocompleteField
     * @public
     * @param {HTMLInputElement} input - Input element
     */
    autocompleteField(input) {
        this.autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['geocode'],
            componentRestrictions: { country: ['IT'] },
            fields: ['name', 'address_component', 'geometry'],
        });

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            const place = this.autocomplete.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.performAjaxRequest(lat, lng);
        });
    }

    /**
     * Get latitude and longitude from address
     * @memberof PickupInStore
     * @method getLatLng
     * @public
     * @param {Object} address - Address object
     */
    getLatLng(address) {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'address': address }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();
                    this.performAjaxRequest(lat, lng);
                } else {
                    console.error('No result found.');
                }
            } else {
                console.error('Geocoder failed: ' + status);
            }
        });
    }

    /**
     * Perform AJAX request to get stores list
     * @memberof PickupInStore
     * @method performAjaxRequest
     * @public
     * @param {*} lat - Latitude
     * @param {*} lng - Longitude
     * @returns 
     */
    performAjaxRequest(lat, lng) {
        if (isNaN(lat) || isNaN(lng) || lat === null || lng === null) {
            const defaultLat = parseFloat($(SELECTORS.defaultLat).data('lat'));
            const defaultLng = parseFloat($(SELECTORS.defaultLng).data('lng'));

            if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
                lat = defaultLat;
                lng = defaultLng;
            } else {
                console.error('Unable to get default coordinates from data-attributes.');
                return;
            }
        }

        const data = {
            lat: lat,
            lng: lng
        };

        const form = this.element.closest('form');
        const url = form.attr('action');

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            success: (response) => {
                if (response.success) {
                    this.storesList.html(response.template);
                } else {
                    alertMessage(response.message, 'cc-alert--danger');
                }
            },
            error: (err) => {
                alertMessage(err.message, 'cc-alert--danger');
            },
        });

        $.spinner().stop();
    }

    /**
     * Use current location handler to get the user location
     * @memberof PickupInStore
     * @method useCurrentLocationHandler
     * @public
     * @returns {void}
     */
    useCurrentLocationHandler() {
        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by your browser.');
            return;
        }

        navigator.geolocation.getCurrentPosition((position) => {
            const geocoder = new google.maps.Geocoder();
            const latlng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            geocoder.geocode({ 'location': latlng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.nodeElement.value = results[0].formatted_address;
                        this.form.submit();
                    } else {
                        console.error('No results found');
                        $.spinner().stop();
                    }
                } else {
                    console.error('Geocoder failed due to: ' + status);
                    $.spinner().stop();
                }
            });
        }, () => {
            console.error('Unable to retrieve your location');
            $.spinner().stop();
        });
    }

    /**
     * Select store handler to select the store
     * @memberof PickupInStore
     * @method selectStoreHandler
     * @public
     * @param {HTMLElement} target - Target element
     * @returns 
     */
    selectStoreHandler(target) {
        const $target = $(target);
        const errorMsg = $target.data('error-message');
        const storeCard = $target.closest(SELECTORS.storeItem);
        const storeData = JSON.parse(storeCard.attr('data-store'));

        if (!storeData) {
            alertMessage(errorMsg, 'cc-alert--danger');
            return;
        }

        this.element.val('');
        this.storesList.html('');

        $('body').trigger('shipping:storeSelected', storeData);

        $(SELECTORS.selectedStoreInput).val(storeData.ID);
        $(SELECTORS.selectedStoreName).val(storeData.name);
        $(SELECTORS.selectedStoreEmail).val(storeData.email);
        $(SELECTORS.storeCardName).text(storeData.name);
        $(SELECTORS.storeCardAddress).text(storeData.formattedAddress);
        $(SELECTORS.storeCardPhone).text(storeData.phone);
        $(SELECTORS.storeCard).attr('data-store', storeCard.attr('data-store'));
        $(SELECTORS.shippingStoreBlock).removeClass('d-none');
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new PickupInStore($(element));
    });
};
