'use strict';
const Player = require('@vimeo/player').default;

const SELECTORS = {
    element: '.js-video',
    playerButton: '.js-video-play',
    video: '.js-video-element',
    videoMobile: '.js-video-mobile',
    videoDesktop: '.js-video-desktop',
    videoPoster: '.js-video-poster'
};

/**
 * Video class that handles the video player
 */
class Video {
    constructor(element) {
        this.element = element;
        this.playerButton = element.find(SELECTORS.playerButton);
        this.video = element.find(SELECTORS.video);
        this.videoMobile = element.find(SELECTORS.videoMobile);
        this.videoDesktop = element.find(SELECTORS.videoDesktop);
        this.videoPoster = element.find(SELECTORS.videoPoster);
        this.iframe = window.isMobile ? this.videoMobile : this.videoDesktop;
        this.player = new Player(this.iframe[0]);
        this.breakpointChanged = window.isMobile;

        this.initEvents();
    }

    initEvents() {
        this.initAccessibilityAttr();
        this.playerButton.on('click', this.playVideo.bind(this));
        this.video.attr('allow', 'fullscreen');
        this.video.attr('allowFullScreen', '');

        $(window).on('resize', this.onResize.bind(this));
    }

    /**
     * Play the video and hide the play button and video poster
     */
    playVideo() {
        this.player.play();
        this.playerButton.fadeOut(250);
        this.videoPoster.fadeOut(250);
    }

    /**
     * On window resize event pause the video and show the play button and video poster again. 
     * Updates the iframe to use the correct video element based on the window width.
     */
    onResize() {
        if (this.breakpointChanged !== window.isMobile) {
            this.player.pause();
            this.playerButton.fadeIn(250);
            this.videoPoster.fadeIn(250);

            this.initAccessibilityAttr();
            this.iframe = window.isMobile ? this.videoMobile : this.videoDesktop;
            this.player = new Player(this.iframe[0]);
            this.breakpointChanged = window.isMobile;
        }
    }
        
    /**
     * Initialize the accessibility attributes for the video player
     */
    initAccessibilityAttr() {
        if (window.isMobile) {
            this.playerButton.attr('aria-controls', this.videoMobile.attr('id'));
        } else {
            this.playerButton.attr('aria-controls', this.videoDesktop.attr('id'));
        }
    }
}

module.exports = () => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                new Video($(entry.target));
                observer.unobserve(entry.target);
            }
        });
    });

    $(SELECTORS.element).each((index, element) => {
        observer.observe(element);
    });
};
