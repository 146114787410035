'use strict';

const SELECTORS = {
    element: '.js-anchor-link',
};

/**
 * AnchorLink class that handles the internal anchor links
 */
class AnchorLink {
    constructor(element) {
        this.element = element;
        this.initEvents();
    }

    initEvents() {
        this.element.on('click', (e) => {
            const href = this.element.attr('href');

            if (href.startsWith('#') && href.length > 1) {
                e.preventDefault();
                this.scrollToTarget(href);
            }
        });
    }

    /**
     * Scrolls to the target element
     * @param {string} href - href attribute of the anchor link
     */
    scrollToTarget(href) {
        const $target = $(href);
        if ($target.length > 0) {
            const navbarHeight = document.documentElement.style.getPropertyValue('--headerHeight').replace('rem', '') * 10;
            const targetOffset = $target.offset().top - navbarHeight;
            $('html, body').animate({ scrollTop: targetOffset }, 'slow');
        }
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new AnchorLink($(element));
    });
};
