'use strict';

const SELECTORS = {
  userLogged: '.js-logged',
  dropdownButton: '.js-dropdown-button',
  dropdown: '.js-dropdown-menu'
};

const CLASSES = {
  show: 'show'
};

const ATTRIBUTES = {
  ariaExpanded: 'aria-expanded'
};

class DropdownOverlay {
  constructor() {
    this.initEvents();
  }

  initEvents() {
    $(document).on('mouseenter', SELECTORS.userLogged, (e) => {
      $(SELECTORS.dropdown).addClass(CLASSES.show);
    });

    $(document).on('mouseleave', SELECTORS.userLogged, (e) => {
      const isUserLoggedHovering = $(e.currentTarget).is(':hover');
      const isDropdownHovering = $(SELECTORS.dropdown).is(':hover');

      if (!isUserLoggedHovering && !isDropdownHovering) {
        $(SELECTORS.dropdown).removeClass(CLASSES.show);
        $(SELECTORS.dropdownButton).attr(ATTRIBUTES.ariaExpanded, 'false');
      }
    });

    $(document).on('click', SELECTORS.dropdownButton, function (e) {
      window.location.href = $(this).attr('href');
      e.stopPropagation();
    });

    $(document).on('mouseenter', SELECTORS.dropdownButton, function (e) {
      $(this).attr(ATTRIBUTES.ariaExpanded, 'true');
    });
  }
}

const dropdownOverlayInstance = new DropdownOverlay();
