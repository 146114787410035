'use strict';

const SELECTORS = {
    element: '.js-toggle-password',
    trigger: '.js-toggle-btn',
    input: '.js-input',
    closedIcon: '.js-close-icon',
    openIcon: '.js-open-icon'
};

const CLASSES = {
    hidden: 'cc-hidden'
};

/**
 * Password Toggler component
 */
class PasswordToggler {
    constructor(element) {
        this.element = element;
        this.trigger = this.element.find(SELECTORS.trigger);
        this.input = this.element.find(SELECTORS.input);
        this.closedIcon = this.element.find(SELECTORS.closedIcon);
        this.openIcon = this.element.find(SELECTORS.openIcon);
        this.initEvents();
    }

    initEvents() {
        this.trigger.off('click').on('click', (event) => {
            event.preventDefault();
            this.togglePassword();
        });
    }

    /**
     * Toggle password visibility on input field, by changing the input type and change icon
     */
    togglePassword() {
        const type = this.input.attr('type');
        const closedIcon = this.element.find(SELECTORS.closedIcon);
        const openIcon = this.element.find(SELECTORS.openIcon);
        $(openIcon).toggleClass(CLASSES.hidden);
        $(closedIcon).toggleClass(CLASSES.hidden);
        this.input.attr('type', type === 'text' ? 'password' : 'text');
    }
}

module.exports = {
    initializeClass: function () {
        $(SELECTORS.element).each(function () {
            new PasswordToggler($(this));
        });
    }
}