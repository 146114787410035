'use strict';

const SELECTORS = {
    formControl: 'input.js-form-control, textarea.js-form-control, select.js-select-validate',
    formPassword: '.js-form-password-control',
    formSelect: '.js-form-select:not([disabled])',
    minAgeInput: '.js-min-age-input',
	inputNotDisabled: 'input.js-form-control:not([disabled]):not([data-ignore="true"]), textarea.js-form-control:not([disabled]):not([data-ignore="true"]), select.js-select-validate',
	formAccount: '.js-prefilled-form',
	currentPassword: '.js-current-password'
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */

function clearFormErrors(form) {
	$(form).find('.form-control.cc-invalid').removeClass('cc-invalid');
	$(form).find('.invalid-feedback').removeClass('cc-message--visible').text('');
	$(form).find('.cc-form__wrap--error').removeClass('cc-form__wrap--error');
}

var validatedFieldsPerForm = {};

$(SELECTORS.formAccount).each(function() {
	var form = $(this);
    var formName = form.attr('name');
	var submitButton = form.find('button[type="submit"]');

	var validatedFields = validatedFieldsPerForm[formName] || {};
	form.find(SELECTORS.inputNotDisabled).each(function(index,val) {
        var field = $(val);
        var fieldName = field.attr('name');
        var fieldValue = field.val().trim();
		if(fieldName === undefined){
			return;
		}
        validatedFields[fieldName] = fieldValue !== '' ? true : false;
		
    });
    
    validatedFieldsPerForm[formName] = validatedFields;
	
	var allFieldsValid = Object.values(validatedFieldsPerForm[formName]).every(value => value === true);
	if (allFieldsValid && !form.hasClass('cc-novalidate')) {
		submitButton.removeClass('cc-disabled').prop('disabled', false);
	}
});

$('body').on('blur change input show.bs.modal', SELECTORS.formControl, function() {
	var form = $(this).closest('form');
	var validatedFields = validatedFieldsPerForm[form.attr('name')] || {};
	validateField(this, validatedFields);
});

/**
 * Clear form validation for a specific form
 */
$('body').on('formValidation:clear', function(e, name) {
	if (name) {
		validatedFieldsPerForm[name] = {};
	} else {
		validatedFieldsPerForm = {};
	}
});

/**
 * Remove error status from input field
 * @param {JQuery} divParent - The parent div of the input field
 * @param {JQuery} feedbackElement - The feedback element to be shown with the error message
 */
function removeErrorStatus (divParent, feedbackElement) {
	feedbackElement.removeClass('cc-message--visible');
	divParent.removeClass('cc-form__wrap--error');
	divParent.find('.cc-icon--success').show();
	divParent.find('.cc-icon--warning').hide();
	divParent.find('.cc-icon--error').hide();
}

/**
 * Add error status to input field
 * @param {JQuery} divParent - The parent div of the input field
 * @param {JQuery} feedbackElement - The feedback element to be hidden with the error message
 */
function addErrorStatus (divParent, feedbackElement) {
	feedbackElement.addClass('cc-message--visible');
	divParent.addClass('cc-form__wrap--error');
	divParent.find('.cc-icon--success').hide();
	divParent.find('.cc-icon--warning').hide();
	divParent.find('.cc-icon--error').show();
}

function validateField(field, validatedFields) {
	var isValid = true;
	var form = $(field).closest('form');
	var formName = form.attr('name');
	var fieldValue = $(field).val().trim();
	var fieldName = $(field).attr('name');
	var errorMessage = $(field).data('missing-error');
	var rangeErrorMessage = $(field).data('range-error');
	var patternErrorMessage = $(field).data('pattern-mismatch');
	var patternErrorAddress = $(field).attr('pattern');
	var addressErrorMessage = $(field).closest('.js-form-wrap').find('.invalid-feedback').data('errormsg');
	var patternErrorCap = $(field).data('cap');
	var capErrorMessage = $(field).closest('.js-form-wrap').find('.invalid-feedback').data('errormsg');
	var feedbackElement = $(field).closest('.cc-form__wrap').find('.invalid-feedback');
	var divParent = $(field).closest('.cc-form__wrap');
	var iconError = $('.js-icon--warning.d-none');
	var submitButton = form.find('button[type="submit"]');
	var totalFields = $(field).closest('form').find(SELECTORS.inputNotDisabled).length;

	if (fieldValue === '' && $(field).prop('required')) {
		if (feedbackElement.length === 0) {
			$(field).after('<div class="invalid-feedback">' + errorMessage + '</div>');
		} else {
			addErrorStatus(divParent, feedbackElement);
			feedbackElement.addClass('cc-message--visible').text(errorMessage);
		}
		isValid = false;
	} else {
		feedbackElement.text('');
	}

	if (rangeErrorMessage && fieldValue !== '' && !$(field).data('password')) {
		var min = parseFloat($(field).attr('minlength'));
		var max = parseFloat($(field).attr('maxlength'));
		var value = parseFloat(fieldValue.length);
		if (isNaN(value) || value < min || value > max) {
			if (feedbackElement.length === 0) {
				$(field).after('<div class="invalid-feedback">' + rangeErrorMessage + '</div>');
			} else {
				addErrorStatus(divParent, feedbackElement);
				feedbackElement.addClass('cc-message--visible').text(rangeErrorMessage);
			}
			isValid = false;
		}
	}


	if ($(field).data('confirm-password')) {
		var $form = $(field).closest('form');
		var passwordField = $form.find(SELECTORS.formPassword);
		var confirmPasswordValue = fieldValue;
		var passwordValue = passwordField.val().trim();
		var confirmFeedbackElement = $(field).closest('.js-form-wrap').find('.invalid-feedback');
		if (confirmPasswordValue !== passwordValue) {
			var confirmErrorMessage = $(confirmFeedbackElement).data('errormsg');
			if (confirmFeedbackElement.length === 0) {
				$(field).after('<div class="invalid-feedback">' + confirmErrorMessage + '</div>');
			} else {
				addErrorStatus(divParent, confirmFeedbackElement);
				confirmFeedbackElement.addClass('cc-message--visible').text(confirmErrorMessage);
			}
			isValid = false;
		} else {
			confirmFeedbackElement.text('');
		}
	}

	if ($(field).data('password')) {
		
		var errorMessage = '';
		var samePasswordErrorMessage = $(field).data('same-password-error');
		var feedbackElement = divParent.find('.invalid-feedback');

		var confirmFieldElement = $('[data-confirm-password]');
		var confirmFieldName = confirmFieldElement.attr('name');
		var confirmField = confirmFieldElement.val();
		var confirmFieldFeedback = confirmFieldElement.closest('.js-form-wrap').find('.invalid-feedback');
		var confirmedErrorMessage = confirmFieldFeedback.data('errormsg');
		var divParentConfirmField = confirmFieldElement.closest('.js-form-wrap');

		if (!/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(fieldValue)) {
			errorMessage = $(feedbackElement).data('errormsg');
			isValid = false;
		}
	
		var currentPasswordWrapper = $(form).find(SELECTORS.currentPassword);
		if (currentPasswordWrapper.length > 0) {
			var currentPasswordField = currentPasswordWrapper.find(SELECTORS.formControl);
			var currentPasswordValue = currentPasswordField.val().trim();
			if (fieldValue === currentPasswordValue) {
				errorMessage = samePasswordErrorMessage;
				isValid = false;
			}
		}
	
		if (errorMessage) {
			if (feedbackElement.length === 0) {
				$(field).after('<div class="invalid-feedback cc-message cc-message--visible">' + errorMessage + '</div>');
			} else {
				addErrorStatus(divParent, feedbackElement);
				feedbackElement.addClass('cc-message--visible').text(errorMessage);
			}
		} else {
			feedbackElement.text('');
			removeErrorStatus(divParent, feedbackElement);
		}

		if (confirmField != undefined){
			if (fieldValue == confirmField && isValid  ) {
				confirmFieldFeedback.text('');
				removeErrorStatus(divParentConfirmField, confirmFieldFeedback);
				validatedFieldsPerForm[formName][confirmFieldName] = true;
			} 
			if(fieldValue != confirmField && confirmField.length != '') {
				confirmFieldFeedback.text(confirmedErrorMessage);
				addErrorStatus(divParentConfirmField, confirmFieldFeedback);
				$(confirmFieldElement).after(iconError.clone().removeClass('d-none'));
				validatedFieldsPerForm[formName][confirmFieldName] = false;
			}
		}
	}

	if (patternErrorMessage && fieldValue !== '') {
		var pattern = new RegExp($(field).attr('pattern'));
		if (!pattern.test(fieldValue)) {
			if (feedbackElement.length === 0) {
				$(field).after('<div class="invalid-feedback">' + patternErrorMessage + '</div>');
			} else {
				addErrorStatus(divParent, feedbackElement);
				feedbackElement.text(patternErrorMessage);
			}
			isValid = false;
		}
	}

	if ($(field).data('capitalize-first')) {
		var value = $(field).val();
        if (value) {
			value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
			$(field).val(value);
        }
    }

	if (fieldName === 'dwfrm_profile_customer_birthday' || fieldName === 'dwfrm_newPasswords_birthday') {
		var minVal = $(field).attr('min');
		var birthDateValid = validateBirthDate(fieldValue,minVal);
		if (birthDateValid === 'before') {
			var birthDateErrorMessage = $(field).data('error-age');
		} else if (birthDateValid === 'after') {
			var birthDateErrorMessage = $(field).data('min-age');
		}
	
		if (birthDateErrorMessage) {
			if (feedbackElement.length === 0) {
				$(field).after('<div class="invalid-feedback">' + birthDateErrorMessage + '</div>');
			} else {
				addErrorStatus(divParent, feedbackElement);
				feedbackElement.text(birthDateErrorMessage);
			}
			isValid = false;
		}
	}

	if ($(field).hasClass('cc-select')) {
		var selectedValue = $(field).val();
		var selectError = $(field).data('missing-error');
		var fieldName = $(field).attr('name');
		if (selectedValue !== '' && selectedValue !== undefined ) {
			feedbackElement.text('');
			removeErrorStatus(divParent, feedbackElement);
			isValid = true;
		} else {
			addErrorStatus(divParent, feedbackElement);
			feedbackElement.text(selectError);
			isValid = false;
		}
	}

	if ($(field).data('address')) {
	
		var regex = new RegExp(patternErrorAddress);
	
		if (!regex.test(fieldValue)) {
			addErrorStatus(divParent, feedbackElement);
			isValid = false;
		}
	}

	if ($(field).data('cap')) {
		var regex = new RegExp(patternErrorCap);
	
		if (!regex.test(fieldValue)) {
			addErrorStatus(divParent, feedbackElement);
			feedbackElement.text(capErrorMessage);
			isValid = false;
		}
	}
	
	if (!validatedFieldsPerForm[formName]) {
		validatedFieldsPerForm[formName] = {};
	}

	if (fieldName !== undefined) {
	validatedFieldsPerForm[formName][fieldName] = isValid;
	}
	
	var validFieldsCount = Object.values(validatedFieldsPerForm[formName]).filter(function(item) {
		return item === true;
	});

	if (validFieldsCount.length >= totalFields && validFieldsCount.length >= Object.keys(validatedFieldsPerForm[formName] || {}).length) {
		submitButton.removeClass('cc-disabled').prop('disabled', false);
	} else if (!form.hasClass('cc-novalidate')) {
		submitButton.addClass('cc-disabled').prop('disabled', true);
	}
	if (isValid) {
		removeErrorStatus(divParent, feedbackElement);
	}

	if (!isValid) {
		if (divParent.find('.cc-icon--warning').length == 0) {
			$(field).after(iconError.clone().removeClass('d-none'));
		}
	}
	return isValid;

}

function validateBirthDate(birthDate,minVal) {
	var date = $(SELECTORS.minAgeInput).attr('minlength');
	var minDate = new Date(minVal);
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - date);
    var inputDate = new Date(birthDate);

    if (inputDate < minDate) {
        return "before";
    }

    if (inputDate > maxDate) {
        return "after";
    }

    return true;
}

function validateForm(form) {
	var isValid = true;

	$(form).find(SELECTORS.formControl).each(function() {
		if (!validateField(this)) {
			isValid = false;
		}
	});

	return isValid;
}


module.exports = function(formElement, payload, invalidFeed = '', containerInput = '') {
	// clear form validation first
	clearFormErrors(formElement);
	$('.alert', formElement).remove();
	var isFormValid = validateForm(formElement);

	if (!isFormValid) {
		return; // if form is valid, don't send it and show error
	}

	if (typeof payload === 'object' && payload.fields) {
		Object.keys(payload.fields).forEach(function(key) {
			if (payload.fields[key]) {
				var feedbackElement = $(formElement).find('[name="' + key + '"]')
					.parent()
					.children('.invalid-feedback');

				if (feedbackElement.length > 0) {
					if (Array.isArray(payload[key])) {
						feedbackElement.html(payload.fields[key].join('<br/>'));
					} else {
						feedbackElement.html(payload.fields[key]);
					}
					feedbackElement.siblings('.form-control').addClass('is-invalid');
				}
			}
		});
	}
	if (payload && payload.error) {
		var form = $(formElement).prop('tagName') === 'FORM' ? $(formElement) : $(formElement).parents('form').find('.invalid-feedback');

		if (invalidFeed != '') {
			addErrorStatus(containerInput, invalidFeed);
			invalidFeed.append(payload.error.join('<br/>'));
		} else {
			form.append('<div class="alert alert-danger" role="alert">' +
				payload.error.join('<br/>') + '</div>');
		}
	}
};