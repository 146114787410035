'use strict'

const SELECTORS = {
    element: '.js-hero-slider',
    sliderPlay: '.js-hero-slider-play'
}

class Hero {
    constructor(element) {
        this.element = element;
        this.sliderPlay = element.find(SELECTORS.sliderPlay);

        this.initEvents();
    }

    initEvents() {
        this.sliderPlay.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.sliderPlay.hasClass('cc-paused')) {
                this.sliderPlay.removeClass('cc-paused');
                this.sliderPlay.closest('.cc-hero-slider').find('.js-slider').slick('slickPlay');
            } else {
                this.sliderPlay.addClass('cc-paused');
                this.sliderPlay.closest('.cc-hero-slider').find('.js-slider').slick('slickPause');
            }
        });
    }

}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new Hero($(element));
    });
}