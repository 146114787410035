'use strict'

function seClerkSession() {
    let isclerksession = $('#js-page-dataset').data('clerksession');
    let isclerklogged = $('#js-page-dataset').data('clerklogged');
    if (isclerksession) {
        sessionStorage.setItem('isClerkSession', 'true');

        if (isclerklogged) sessionStorage.setItem('createClerkSession', 'false');
    }

    let clerkSession = JSON.parse(sessionStorage.getItem('isClerkSession'));
    let createSession = JSON.parse(sessionStorage.getItem('createClerkSession'));
    let clerk = JSON.parse(sessionStorage.getItem('employee'));
    if ((!isclerksession && clerkSession) || (isclerksession && !isclerklogged && !createSession && clerk)) {
        createClerkSession();
    }
}

function createClerkSession() {
    const sessionExpiredModal = $('#clerkSessionExpired');

    // OPEN EXPIRED SESSION MODAL
    sessionExpiredModal.modal('show');

    sessionExpiredModal.on('hidden.bs.modal', async function () {
        if ($.spinner()) $.spinner().start();

        let url = $('#js-page-dataset').data('createclerksession');
        const response = await fetch(url);

        if (!response.ok) {
            console.error('Impossible to create a new clerk session');
            return;
        }

        const data = await response.json();

        sessionStorage.setItem('createClerkSession', 'true');

        if (data) {
            window.location.href = data.redirectUrl;
        } else {
            if ($.spinner()) $.spinner().stop();
            console.error('No data for redirect');
        }
    });
}


$(document).ready(function () {
    seClerkSession();
});