'use strict';

const JsBarcode = require('jsbarcode');
const { alertMessage } = require('../common/common');

const SELECTORS = {
  fideltyCardShow: '.js-fideltycard-show',
  fideltyCardReset: '.js-fideltycard-reset',
  codeCopy: '.js-code-copy',
  wrapFideltyCard: '.cc-wrap-fideltycard',
  buttonShowCouponPage: '.js-toggle-coupon-page',
  wrapCouponCodePage: '.js-couponcode-page',
  wrapCouponInfoPage: '.js-coupon-info-page',
  barCodePageBtn: '.js-copy-bar-code',
  jsCodeOnpage: '.js-bcode',
  fideltyCardShowBarcde: '.js-fideltycard-show-barCode',
  infoCardShowBarcde: '.js-info-barCode',
  couponCardOnPage: '.js-coupon-card',
  fideltyLabelShow: '.js-show-code',
  fideltyLabelHide: '.js-hide-code'
};

const CLASSES = {
  hidden: 'd-none',
  couponSelected: 'js-coupon-selected',
  couponStyleClass: 'cc-coupon-selected'
};

const MESSAGES = {
  copySuccess: 'Codice Copiato'
};

class FideltyCardManager {
  constructor() {
    this.initEvents();
  }

  initEvents() {
    $(document).ready(() => {

      $(SELECTORS.couponCardOnPage).each((index, element) => {
        this.checkSelectedCouponOnPage(element);
      });

      $(SELECTORS.fideltyCardShow).click((e) => {
        this.handleShowClick(e);
      });

      $(SELECTORS.fideltyCardReset).click((e) => {
        this.handleResetClick(e);
      });

      JsBarcode('.barcode').init();

      $(SELECTORS.buttonShowCouponPage).each((index, element) => {
        $(element).click((element) => {
          this.showCouponCodeOnPage(element.target);
        });
      });

      $(SELECTORS.barCodePageBtn).click((e) => {
        this.copyBarCodePage(e);
      });

      $(SELECTORS.fideltyCardShowBarcde).click((e) => {
        this.showFideltyCardBarcode(e);
      });
    });
  }

  handleShowClick(event) {
    $(SELECTORS.wrapFideltyCard).toggleClass(CLASSES.hidden);
    $(event.currentTarget).hide();
  }

  handleResetClick(event) {
    const codice = $(SELECTORS.codeCopy).text().trim();
    if (codice !== '') {
      this.copyToClipboard(codice);
    }
    $(SELECTORS.wrapFideltyCard).addClass(CLASSES.hidden);
    $(SELECTORS.fideltyCardShow).show();
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(function() {
      alertMessage(MESSAGES.copySuccess, 'cc-alert--success');
    }, function(err) {
      console.error('Error: ', err);
    });
  }

  showCouponCodeOnPage(element) {
    const btn = element;
    const $infoElement = $(btn).closest($(SELECTORS.wrapCouponInfoPage));
    $infoElement.find(SELECTORS.wrapCouponCodePage).toggle();
  }

  copyBarCodePage(e){
    const bCode = $(e.currentTarget).closest(SELECTORS.wrapCouponInfoPage).find(SELECTORS.jsCodeOnpage).text();
    if (bCode !== '') {
      this.copyToClipboard(bCode);
      alertMessage(MESSAGES.copySuccess, 'cc-alert--success');
    }
  }

  showFideltyCardBarcode(event){
    $(SELECTORS.infoCardShowBarcde).toggleClass(CLASSES.hidden);
    $(SELECTORS.fideltyLabelShow).toggleClass(CLASSES.hidden);
    $(SELECTORS.fideltyLabelHide).toggleClass(CLASSES.hidden);
  }

  checkSelectedCouponOnPage(element){
    const coupSel = $(element);

    if (coupSel.hasClass(CLASSES.couponSelected)) {
        const btnToggle = coupSel.find(SELECTORS.buttonShowCouponPage).first();
        this.showCouponCodeOnPage(btnToggle);
        setTimeout(() => {
          coupSel.removeClass(CLASSES.couponStyleClass)
        },5000);
    }
  }
}

const fideltyCardManagerInstance = new FideltyCardManager();
