'use strict';

const { alertMessage } = require('../common/common');

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var icon = $(this).find($('i'));
            var url = $(this).attr('href') || $(this).data('href');
            var pid = $(this).closest('.product').data('pid') || $(this).closest('.product-detail').data('pid');
            var optionId = $('.js-pdp-main').find('.product-option').data('option-id');
            var optionVal = $('.js-pdp-main').find('.options-select.selected').data('value-id');
            var countWrap = $('.js-wishlist');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            var $el = $(this);

            if ($(this).hasClass('js-added-wishlist')) {
                url = $(this).data('remove');
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if (data.success) 
                            $el.removeClass('js-added-wishlist');
                            $el.removeClass('cc-icon-wishlist--added');
                        
                        if (typeof data.wishlistLength !== "undefined") {
                            if (data.wishlistLength === 0) {
                                $(countWrap).addClass('d-none');
                                $(countWrap).removeClass('d-inline-flex');
                            } else {
                                $(countWrap).addClass('d-inline-flex');
                                $(countWrap).removeClass('d-none');
                            }
                            $(countWrap).text(data.wishlistLength);
                        }

                        if (data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            alertMessage(data.msg, 'cc-alert--success');
                        }

                        if ($el.closest('.js-product-slider.js-wishlist-slider').length > 0) {
                            const $slickSlider = $el.closest('.js-product-slider').find('.js-slider');
                            const slideIndex = $el.closest('.slick-slide').index();
                            $slickSlider.slick('slickRemove', slideIndex);
                        }
                    },
                    error: function (err) {
                        alertMessage(err.msg, 'cc-alert--danger');
                    }
                });
            } else {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    },
                    success: function (data) {
                      
                        if (data.success) 
                            $el.addClass('js-added-wishlist');
                            $el.addClass('cc-icon-wishlist--added');

                        if (data.wishlistLength){ 
                            if (data.wishlistLength === 0) {
                                $(countWrap).addClass('d-none');
                                $(countWrap).removeClass('d-inline-flex');
                            } else {
                                $(countWrap).addClass('d-inline-flex');
                                $(countWrap).removeClass('d-none');
                            }
                            $(countWrap).text(data.wishlistLength);
                        }
                        if (data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            alertMessage(data.msg, 'cc-alert--success');
                        }
                    },
                    error: function (err) {
                        alertMessage(err.msg, 'cc-alert--danger');
                    }
                });
            }
        });
    }
};
